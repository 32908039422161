import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./navigation.module.scss"

const Navigation = ({ siteTab }) => (
  <StaticQuery
    query={
      graphql`
        query
        {
          allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
              node{
                id
                frontmatter {
                  title,
                  policy
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `
    }

    render={data => (
       <section className={ styles.content__navigation }>
           <Link className= { styles.navigation__tab } to="/travel/">
               The world from an engineer's point of view
           </Link>
           {data.allMarkdownRemark.edges.map(({ node }) => {
             if ( node.frontmatter.policy === "show" ) {
               return <Link
                 key={ node.id }
                 className={ siteTab === node.fields.slug ? styles.navigation__tabTravel : styles.navigation__tab }
                 to={ node.fields.slug }
               >
                 { node.frontmatter.title }
               </Link>
             }

             return <></>
           })}
       </section>
    )}
  />
)

Navigation.propTypes = {
  siteTab: PropTypes.string,
}

Navigation.defaultProps = {
  siteTab: ``,
}

export default Navigation
