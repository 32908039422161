import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import styles from "./share.module.scss"


const Share = ({ socialConfig, tags }) => (
  <div className={ styles.socialPost }>
    <FacebookShareButton url={socialConfig.config.url} className={ styles.socialPost__logo } >
	  	<span className="icon">
	  		<FacebookIcon size={ 32 } round={ true } />
	  	</span>
	  </FacebookShareButton>
	  <TwitterShareButton url={socialConfig.config.url} className={ styles.socialPost__logo } title={socialConfig.config.title} via={socialConfig.twitterHandle.split('@').join('')} hashtags={tags} >
	  	<span className="icon">
	  		<TwitterIcon size={ 32 } round={ true } />
	  	</span>
	  </TwitterShareButton>
	  <WhatsappShareButton url={socialConfig.config.url} className={ styles.socialPost__logo } title={socialConfig.config.title} >
	  	<span className="icon">
	  		<WhatsappIcon size={ 32 } round={ true } />
	  	</span>
	  </WhatsappShareButton>
  </div>
)

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};

export default Share;
