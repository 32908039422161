import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Share from "../components/share"

const ArticlePage = ({ data }) => {
  const post = data.markdownRemark
  const site = data.site
  const articleImage = require(`../../static/huellas${post.fields.slug}header.jpg`)
  const style = css`
    background-image: url(${ articleImage });
  `

  function scriptHeader(url) {
    if (url) {
      return <Helmet>
               <script async="" src={ url }></script>
             </Helmet>
    }

    return <></>
  }

  return (
    <Layout>
      <Navigation siteTab={ post.fields.slug }/>

      <section className="content__body--article">
         <h1>{ post.frontmatter.title }</h1>
         <h2>{ post.frontmatter.date }</h2>
         <div className="cover-image" css={ style }/>
         <div dangerouslySetInnerHTML={{ __html: post.html }} />
         <Share
           socialConfig={{
             twitterHandle: site.siteMetadata.twitterHandle,
             config: {
               url: `${site.siteMetadata.url}${post.fields.slug}`,
               title: post.frontmatter.title,
             },
           }}
           tags={ post.frontmatter.tags }
         />
      </section>

      { scriptHeader(post.frontmatter.scriptUrl) }
    </Layout>
  )
}

export default ArticlePage


export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY HH:MM")
        scriptUrl
        tags
      }
      fields {
        slug
      }
    }
  }
`
